<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="w-100 store">
		<b-card-group
			v-if="!isAdminOrCorporate && !earlyLaunch && !$user.details().hide_store"
			id="our-products">
			<b-card
				class="border-0 bg-white mb-0"
				:style="{ paddingTop: $replicated.replicatedCheckPromo() ? '140px' : '80px' }">
				<div
					class="row px-4 mx-auto container">
					<div class="col">
						<div
							class="row">
							<div
								class="col"
								style="margin-bottom: 25px">
								<h2
									class="store-tagline"
									v-html="translate('agency_store_tagline')" />
							</div>
						</div>
						<div
							v-if="hasData() && !loadingProducts"
							class="text-center">
							<store-product
								:products="combinedMainProducts"
								:loading="addProductsLoading"
								:col-class-override="['xs', 'sm'].includes(windowWidth) ? 'col-12' : 'col-6 col-md-3 col-lg-3'"
								@add_product="addProduct" />
							<div v-if="thereIsExchange">
								<p class="exchange-text text-center small mt-4">
									{{ translate('exchange_disclaimer') }}
								</p>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="row">
					<store-products-banner
						v-if="!loadingProducts"
						:products-import="[TN, LT]"
						:products-reference="data"
						:background-style="['xs', 'sm'].includes(windowWidth) ? 'background-size: auto; background-position: 35% 0%' : 'background-size: cover; background-position: bottom left;'"
						:show-detail-button="[true, true]"
						:show-price="[false, false]" />
				</div> -->
				<div style="height: max-content">
					<reviews
						:title-color="reviewsData.titleColor"
						:text-color="reviewsData.textColor"
						:reviews="reviewsData.reviews" />
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loadingProducts"
					class="mx-auto" />
				<div class="border border-bottom-0 border-x-0 border-light" />
			</b-card>
		</b-card-group>
		<request-sponsor-modal
			v-model="showStoreModal"
			:redirect-type="redirectType" />
	</div>
</template>
<script>

import { sidebarCssClasses } from '@coreui/vue/src/shared/classes';
import cookie from 'vue-cookie';
import { META_DESCRIPTION } from '@/settings/Meta';
import {
	Store, Validations, Products, Purchase, Agency, AgencyTaglines,
} from '@/translations';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { AGENCY_PRODUCTS } from '@/settings/Products';
import { SHOW_FROM_DATE, END_DATE, COUNTDOWN_TIMEZONE } from '@/settings/Countdown';
import { S3_PATH } from '@/settings/Images';
import { distributor } from '@/settings/Roles';
import EventBus from '@/util/eventBus';
import ProductMix from '@/mixins/Products';
import WindowSizes from '@/mixins/WindowSizes';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import AgencySite from '@/mixins/AgencySite';
import Cart from '@/util/Cart';
import { trackEvent } from '@/util/GoogleTagManager';
import StoreProduct from './components/StoreProduct';
// import StoreProductsBanner from './components/StoreProductsBanner';
import { ONLY_TUUN_COUNTRIES } from '@/settings/Register';
import PublicStore from '@/mixins/PublicStore';
import RequestSponsorModal from '@/components/RequestSponsorModal';
import Reviews from '@/views/Agency/Store/components/Reviews.vue';
import { AGENCY_STORE_REVIEWS, contentStyles } from '@/settings/Agency/Store';
import { applyAttrsToHtmlString } from '@/util/HtmlHelper';
// import Countdown from '@/mixins/Countdown';
// import BigCountdown from './components/BigCountdown';

export default {
	name: 'AgencyStore',
	metaInfo() {
		return {
			meta: [
				{ vmid: 'description', name: 'description', content: META_DESCRIPTION.store },
			],
		};
	},
	messages: [Store, Validations, Products, Purchase, Agency, AgencyTaglines],
	components: {
		Reviews,
		// BigCountdown,
		// StoreProductsBanner,
		StoreProduct,
		RequestSponsorModal,
	},
	mixins: [ProductMix, WindowSizes, EarlyLaunch, PublicStore, AgencySite],
	data() {
		return {
			addProducts: new Cart(),
			createCart: new Cart(),
			alert: new this.$Alert(),
			MIN_QTY,
			MAX_QTY,
			distributor,
			S3_PATH,
			clicked: 0,
			theme: process.env.VUE_APP_THEME,
			videoSteps: 3,
			countdownStart: SHOW_FROM_DATE,
			countdownEnds: END_DATE,
			countdownTimezone: COUNTDOWN_TIMEZONE,
			videoURL: {
				en: { // English version
					1: 'https://player.vimeo.com/video/461983332',
					2: 'https://player.vimeo.com/video/809848435',
					3: 'https://player.vimeo.com/video/554405473',
				},
				es: { // Spanish version
					1: 'https://player.vimeo.com/video/472618597',
					2: 'https://player.vimeo.com/video/818370591',
					3: 'https://player.vimeo.com/video/554405473',
				},
				ja: { // Japanese version
					1: 'https://player.vimeo.com/video/461983332',
					2: 'https://player.vimeo.com/video/809848435',
					3: 'https://player.vimeo.com/video/554405473',
				},
			},
			ONLY_TUUN_COUNTRIES,
			contentStyles,
		};
	},
	computed: {
		reviewsData() {
			const { reviews, titleColor, textColor } = AGENCY_STORE_REVIEWS;

			const result = reviews.map((review) => {
				const {
					image,
					author,
					content,
					stars,
					color,
				} = review;

				return {
					image: this.requireAvatar(image),
					author,
					content: applyAttrsToHtmlString(`<b>"</b>${this.translate(content)}<b>"</b>`, this.contentStyles(color)),
					stars,
					color,
				};
			});

			return {
				reviews: result,
				titleColor,
				textColor,
			};
		},
		videoLanguageUrl() {
			return this.videoURL[this.language] ? this.videoURL[this.language] : this.videoURL.en;
		},
		addProductsLoading() {
			return !!this.addProducts.data.loading;
		},
		buttonClass() {
			return	{
				'min-width': ['xs', 'sm'].includes(this.windowWidth) ? '90%' : '450px',
				'min-height': !['xs', 'sm'].includes(this.windowWidth) ? '64px' : '',
				'font-size': !['xs', 'sm'].includes(this.windowWidth) ? '24px' : '',
				'text-align': 'center',
			};
		},
		data() {
			try {
				const products = [];
				this.products.forEach((item) => {
					// eslint-disable-next-line prefer-const
					let { exchange_price: exchangePrice, promo_price: promoPrice, promo_price_amount: promoPriceAmount } = item.attributes;
					let price = this.getProductPrice(item.attributes);

					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}

					products.push({
						image: item.attributes.image,
						code_name: item.attributes.code_name,
						decoded_name: item.attributes.decoded_name,
						sku: item.attributes.sku,
						price,
						priceAmount: item.attributes.price_amount,
						promoPrice,
						promoPriceAmount,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						has_configurations: item.attributes.has_configurations,
						thumbnail: item.attributes.thumbnail,
						detailsLink: item.attributes.details_link,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		tuunsData() {
			try {
				const products = [];

				// Also include LT and TN child products
				const tuunChildren = Object.values(AGENCY_PRODUCTS.Tuun.data.attributes.children).map((item) => {
					// set object with 'attributes' key and value of item
					const obj = {
						attributes: item,
					};

					return obj;
				});

				this.updateManyAgencyProductsPrice(tuunChildren).forEach((item) => {
					// eslint-disable-next-line prefer-const
					let { exchange_price: exchangePrice, promo_price: promoPrice, promo_price_amount: promoPriceAmount } = item.attributes;
					let price = this.getProductPrice(item.attributes);

					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}

					products.push({
						image: item.attributes.image,
						code_name: `${item.attributes.code_name}_simplified`,
						decoded_name: 'Tuun',
						sku: item.attributes.sku,
						price,
						priceAmount: item.attributes.price_amount,
						promoPrice,
						promoPriceAmount,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						has_configurations: item.attributes.has_configurations,
						thumbnail: item.attributes.thumbnail,
						detailsLink: item.attributes.details_link,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		litesData() {
			try {
				const products = [];

				// Also include LT and TN child products
				const liteChildren = Object.values(AGENCY_PRODUCTS.Lite.data.attributes.children).map((item) => {
					// set object with 'attributes' key and value of item
					const obj = {
						attributes: item,
					};

					return obj;
				});

				this.updateManyAgencyProductsPrice(liteChildren).forEach((item) => {
					// eslint-disable-next-line prefer-const
					let { exchange_price: exchangePrice, promo_price: promoPrice, promo_price_amount: promoPriceAmount } = item.attributes;
					let price = this.getProductPrice(item.attributes);

					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}

					products.push({
						image: item.attributes.image,
						code_name: item.attributes.code_name,
						decoded_name: 'Lite',
						sku: item.attributes.sku,
						price,
						priceAmount: item.attributes.price_amount,
						promoPrice,
						promoPriceAmount,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						has_configurations: item.attributes.has_configurations,
						thumbnail: item.attributes.thumbnail,
						detailsLink: item.attributes.details_link,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		// Merge data with tuunsData and liteData
		combinedMainProducts() {
			return [
				...this.data,
				...this.litesData,
				...this.tuunsData,
			];
		},
		// Countdown properties
		// countdownBackground() {
		// 	if (this.countdownDone) {
		// 		return {
		// 			background: `url(${this.anniversaryBg})`,
		// 			'background-size': 'cover',
		// 			'border-radius': '0px',
		// 			'background-position': 'top left',
		// 		};
		// 	}
		// 	return {};
		// },
		// anniversaryBg() {
		// 	return require('@/assets/images/themes/Velovita/background_store_anniversary.png'); // eslint-disable-line global-require
		// },
		thereIsExchange() {
			const theresExchange = this.products.find((item) => item.attributes.exchange);
			return typeof theresExchange !== 'undefined';
		},
		otherProductsData() {
			try {
				const products = [];
				this.otherProducts.forEach((item) => {
					if (item.attributes.code_name !== 'ON1001') { //	Skip Organic Networker - Soft cover
						let { price } = item.attributes;
						let exchangePrice = item.attributes.exchange_price;
						if (this.hasDiscount(item)) {
							price = this.discount(item).value;
							exchangePrice = this.discount(item).exchange_price;
						}

						products.push({
							image: item.attributes.image,
							sku: item.attributes.sku,
							code_name: item.attributes.code_name,
							price,
							price_amount: item.attributes.price_amount,
							quantity: 1,
							exchange: item.attributes.exchange,
							exchangePrice,
							decoded_name: item.attributes.decoded_name,
							thumbnail: item.attributes.thumbnail,
							selectable: item.attributes.selectable,
							sold_out: item.attributes.sold_out,
						});
					}
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		wearablesProductsData() {
			try {
				const products = [];
				this.wearablesProducts.forEach((item) => {
					let { price } = item.attributes;
					let exchangePrice = item.attributes.exchange_price;
					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}

					products.push({
						image: item.attributes.image,
						sku: item.attributes.sku,
						code_name: item.attributes.code_name,
						price,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						decoded_name: item.attributes.decoded_name,
						thumbnail: item.attributes.thumbnail,
						selectable: item.attributes.selectable,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		swagProductsData() {
			try {
				const products = [];
				this.swagProducts.forEach((item) => {
					let { price } = item.attributes;
					let exchangePrice = item.attributes.exchange_price;
					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}
					products.push({
						image: item.attributes.image,
						sku: item.attributes.sku,
						code_name: item.attributes.code_name,
						price,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						product_name: item.attributes.decoded_name,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		CB() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'CB');
				if (!this.showComboBanner(product, 2)) {
					return null;
				}
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		TP() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'TP');
				if (!this.showComboBanner(product, 3)) {
					return null;
				}
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
					backgroundImg: 'tp-background-blue.jpg',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		TPP() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'TPP');
				if (!this.showComboBanner(product, 4)) {
					return null;
				}
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		PTP() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'PTP');
				if (!this.showComboBanner(product, 5)) {
					return null;
				}
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
					backgroundImg: 'ptp-background-purple.png',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		VP() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'VP');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'text-white',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		VC() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'VC');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		ON() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'ON');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'text-white',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		vipPack() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'PK-VIP');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
					buttonClass: 'btn-primary',
					backgroundImg: 'TN.jpg',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		TICKET() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'TK-AC');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description_short`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		TICKET2() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'TK-VIT');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description_short`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
					buttonClass: 'btn-primary',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
	},
	watch: {
		country(newVal, oldVal) {
			let timeout = 0;
			if (oldVal === null) {
				timeout = 1500;
			}
			setTimeout(() => {
				this.prodData.getProducts(this.getStoredCountry());
			}, timeout);
		},
	},
	created() {
		if (typeof this.$user.details().package === 'string') {
			this.currentPackage = this.$user.details().package;
		}
		sidebarCssClasses.map((item) => document.body.classList.remove(item));

		// start countdown
		// this.useSeconds = true;
		// this.timezone = this.countdownTimezone;
		// this.interval = 1000;
		// this.startTimer();
	},
	mounted() {
		EventBus.$emit('storeViewMounted');
		this.getAvailableDiscounts(this.agencyPromoSwitch, this.agencyDiscountCode);
	},
	methods: {
		requireAvatar(directory) {
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/${directory}`;
		},
		// startTimer() {
		// 	if (!this.countdownEnds) {
		// 		return;
		// 	}
		// 	this.countdown(this.countdownEnds, this.countdownStart);
		// },
		getProductOptions(product) {
			const options = { image: product.thumbnail };
			return options;
		},
		scrollTo(id) {
			EventBus.$emit('scrollTo', id);
		},
		async addProduct(products) {
			if (!this.addProductsLoading) {
				try {
					await this.addProducts.addCartProducts(cookie.get('cart_id'), { products, sponsor_id: this.$replicated.replicatedSponsor() });
					trackEvent(this.$gtm, 'productQtyUpdated'); // TODO: track product quantity and sku
					EventBus.$emit('updateLayoutStoreTotals');
				} catch (e) {
					if (typeof this.addProducts.errors.errors.products !== 'undefined') {
						let response = '';
						this.addProducts.errors.errors.products.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 4000 });
					} else {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				}
			}
		},
		showComboBanner(product, requiredProducts) {
			const selectableProducts = product.selectable.products[this.country] ?? product.selectable.products.default;
			const availableProducts = this.data.reduce((accum, mainProduct) => accum + selectableProducts.includes(mainProduct.decoded_name), 0);
			return availableProducts >= requiredProducts;
		},
		// hasData() was changed from being a computed property due to issues with reactivity
		// TODO: Identify and fix the issue with reactivity and change it back to computed property
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
};
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');

	.store  html body div {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}

	p h5 {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 400 !important;
	}

	.pt-promo {
		padding-top: 3rem;
	}

	.img-max-width{
		max-width: 90%;
	}
	.btn-rounded {
		border-radius: 25px;
	}
	.btn-store-customer {
		/* max-width: 220px; */
		color: #716081;
		background-color: white;
		font-size: 20px;
		border-color: #716081;
		font-weight: bold;
	}
	.btn-store-customer:hover {
		background-color: #e6e6e6;
		border-color: #4e425a;
		transition: 0.7s;
	}
	.btn-store-member {
		/* max-width: 220px; */
		color: white;
		background-color: #716081;
		font-size: 20px;
	}
	.btn-store-member:hover {
		background-color: #4e425a;
		transition: 0.7s;
	}
	.store-tagline {
		font-family: Montserrat, sans-serif !important;
		font-weight: 800 !important;
		text-align: center;
		text-transform: uppercase;
		& span {
			color: #DF703D;
		}
	}
</style>
<style>
	.big-label{
		font-size: 50px;
	}
	.custom-btn.custom-white-btn{
		border: 1px solid white;
		width: auto;
		border-radius: 25px;
		background: transparent;
		color: white;
	}
	.custom-btn.custom-white-btn:hover{
		background: white;
		color: black;
	}

	.custom-btn.custom-primary-btn{
		width: 150px;
		border-radius: 25px;
	}

	.custom-hr{
		opacity: 0.1;
		border-color: white;
	}

	.vnis__input.vnis-custom-input-width{
		width: 90px;
	}

	.vnis.vnis-custom-dimensions {
		width: 170px;
		height: 30px;
	}
	.black-overlay{
		background: rgba(23, 23, 25, 0.5);
	}
	.play-icon{
		min-width: 75px;
		transition: all .3s ease-in-out;
	}
	.play-icon:hover{
		transform: scale(1.1);
	}
	.bolder-text {
		-webkit-text-stroke: 1px #DF703D;
	}
</style>
