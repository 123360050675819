<template>
	<div class="row storeproduct justify-content-around">
		<div
			v-for="(product, key) in products"
			:key="key"
			:class="productClass"
			class="col-6 col-md-3 product d-flex flex-column mw-100 p-1">
			<div>
				<img
					:src="product.image"
					class="img-fluid mx-auto pointer"
					@click="$router.push({ name: getRedirectName('ProductDetails'), params: { productName: product.decoded_name }, query: productDetailsQuery(product) })">
			</div>
			<div
				:class="product.has_configurations && ['xs'].includes(windowWidth) ? 'mb-1': 'mb-0'"
				:style="product.has_configurations && ['xs'].includes(windowWidth) ? '': 'height: 40px;'">
				<h3
					:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'h5' : 'h4'"
					class="text-dark font-weight-bold mb-0">
					{{ translate(product.code_name) }}
				</h3>
				<!--<p
					v-if="product.detailsLink"
					:class="['xs'].includes(windowWidth) ? 'mb-2': ''">
					<a
						class="text-blue font-weight-light"
						target="_blank"
						:href="product.detailsLink">
						{{ translate('learn_more') }}
					</a>
				</p>-->
			</div>
			<div class="flex-1">
				<div v-if="product.promoPriceAmount">
					<s
						class="h5 font-weight-bold text-muted"
						style="opacity: 0.7">
						{{ product.price }}
					</s>
					<p
						:class="['xs'].includes(windowWidth) ? 'h3' : 'h2'"
						class="text-primary">
						{{ product.promoPrice }}
					</p>
				</div>
				<p
					v-else
					:class="['xs'].includes(windowWidth) ? 'h3' : 'h2'"
					class="text-primary">
					{{ product.price }}
				</p>
				<p
					v-if="product.exchange"
					class="h6 exchange-text">
					{{ product.exchangePrice }}*
				</p>
				<p
					class="text-muted"
					:class="['xs'].includes(windowWidth) ? 'mb-3' : ''">
					<template v-if="product.code_name.includes('tuun')">
						{{ translate('tuun_presentation') }}
					</template>
					<template v-else-if="product.code_name.includes('lite')">
						{{ translate('lite_presentation') }}
					</template>
					<template v-else>
						{{ translate(`${product.code_name}_presentation`) }}
					</template>
				</p>
				<div
					:class="{ disabled: loading, 'mx-1': ['sm'].includes(windowWidth) }"
					style="font-weight: 600;"
					class="btn btn-primary p-2 pointer custom-btn custom-primary-btn"
					@click="$router.push({ name: getRedirectName('ProductDetails'), params: { productName: product.decoded_name }, query: productDetailsQuery(product) })">
					{{ translate('learn_more') }}
				</div>
				<!-- <template>
					<number-input-spinner
						v-model="product.quantity"
						:mouse-down-speed="500"
						:min="MIN_QTY"
						:max="MAX_QTY"
						:button-class="'vnis__button bg-primary-alt'"
						:input-class="'vnis__input vnis-input-custom-width'"
						:integer-only="true"
						class="mx-auto mb-4 vnis-custom-dimensions vnis-smaller d-flex justify-content-center" />
					<div
						:class="{ disabled: loading, 'mx-1': ['sm'].includes(windowWidth) }"
						style="font-weight: 600;"
						class="btn btn-primary p-2 pointer custom-btn custom-primary-btn"
						@click="clicked = product.sku;handleAddToCart({ [product.sku]: product.quantity }, getProductOptions(product));">
						<template v-if="loading && clicked === product.sku">
							<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
						</template>
						<template v-else>
							{{ translate('add_to_cart') }}
						</template>
					</div>
				</template> -->
			</div>
		</div>
	</div>
</template>

<script>

// import NumberInputSpinner from 'vue-number-input-spinner';
import { Store, Products } from '@/translations';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import WindowSizes from '@/mixins/WindowSizes';
import AgencySite from '@/mixins/AgencySite';

// NumberInputSpinner.methods.whileMouseDown = function whileMouseDown(callback) {
// 	if (this.timer === null) {
// 		this.timer = setInterval(() => {
// 			callback();
// 		}, this.mouseDownSpeed);
// 	}
// };
//
// NumberInputSpinner.watch.value = function changeValue(val) {
// 	this.numericValue = val;
// };

export default {
	name: 'AgencyStoreProduct',
	messages: [Products, Store],
	components: { /* NumberInputSpinner */ },
	mixins: [WindowSizes, AgencySite],
	props: {
		products: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			default: () => false,
		},
	},
	data() {
		return {
			MIN_QTY,
			MAX_QTY,
			clicked: 0,
		};
	},
	computed: {
		hasByom() {
			return this.isSkuInProducts('BY');
		},
		hasZlem() {
			return this.isSkuInProducts('ZL');
		},
		productClass() {
			const paddingClass = ['sm'].includes(this.windowWidth) ? 'px-1' : '';
			const marginBottomClass = this.windowWidth === 'xs' ? 'mb-3' : 'mb-5'; // Conditional margin class

			const classes = [paddingClass, marginBottomClass];
			if (this.hasByom && this.hasZlem) {
				classes.push('col-md-3');
			}

			return classes;
		},
	},
	methods: {
		isSkuInProducts(sku) {
			return this.products.map((item) => item.sku).includes(sku);
		},
		getProductOptions(product) {
			const options = { image: product.thumbnail };
			return options;
		},
		handleAddToCart(product, options) {
			if (!this.loading) {
				this.$emit('add_product', product, options);
			}
		},
		productDetailsQuery(product) {
			const mapCodeNamesToVariants = {
				tuun_rose_gold_simplified: 'RG',
				tuun_black_stainless_steel_simplified: 'BS',
				tuun_swarovski_diamonds_simplified: 'SD',
				bran_lite: 'BR',
				zlem_lite: 'ZL',
				byom_lite: 'BY',
			};

			if (product.code_name.includes('lite')) {
				// 'presentation'
				return { presentations: mapCodeNamesToVariants[product.code_name] };
			}

			if (product.code_name.includes('tuun')) {
				// 'material_type'
				return { material_type: mapCodeNamesToVariants[product.code_name] };
			}

			return {};
		},
	},
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat');

.storeproduct .custom-btn  html body div {
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 600 !important;
}

p h5 {
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 400 !important;
}

.bottom_b {
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 0px;
}

.flex-1 {
	flex: 1;
}

.text-blue {
	color: #13637f !important;
}
</style>
